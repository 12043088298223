@charset 'UTF-8';

.post-menu {
    position: absolute;
    z-index: 6;
    top: -12px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 4px;
    border: 1px solid transparent;
    border-radius: 4px;
    white-space: normal;
}

.post-menu__item {
    display: inline-flex;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    border-radius: 4px;
    color: rgba(v(center-channel-color-rgb), 0.4);
    fill: rgba(v(center-channel-color-rgb), 0.4);

    &:active,
    &--active,
    &--active:hover {
        background: rgba(var(--button-bg-rgb), 0.08);
        color: v(button-bg);
        fill: v(button-bg);
    }

    span {
        display: flex;
    }
}

.post-menu__item--show {
    display: block;
}

.post-menu__item--wide {
    width: auto;
    padding: 0 6px;
}

.post-menu__item--selected { // Used for the flag icon when it is in the flagged state
    color: var(--link-color);
    fill: var(--link-color);
}

.post-menu__comment-count {
    margin: 0 0 0 2px;
    font-weight: 600;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none;    /* Firefox all */
    -ms-user-select: none;     /* IE 10+ */
    user-select: none;
}

@media (min-width: 768px) {
    .post-menu__item {
        display: none;
    }

    .post-menu__item--show {
        display: block;
    }

    .post {
        &:hover,
        &.post--hovered,
        &.a11y--active {
            .post-menu {
                border-color: rgba(v(center-channel-color-rgb), 0.08);
                background-color: v(center-channel-bg);
                box-shadow: $elevation-1;
            }

            .search-item__jump {
                color: rgba(v(center-channel-color-rgb), 0.56);

                &:hover {
                    color: v(link-color);
                }
            }

            .post-menu__item {
                display: inline-flex;
                color: rgba(v(center-channel-color-rgb), 0.56);
                fill: rgba(v(center-channel-color-rgb), 0.56);

                &:hover {
                    background: rgba(v(center-channel-color-rgb), 0.08);
                    color: rgba(v(center-channel-color-rgb), 0.72);
                    fill: rgba(v(center-channel-color-rgb), 0.72);
                }

                &:active,
                &--active,
                &--active:hover {
                    background: rgba(var(--button-bg-rgb), 0.08);
                    color: v(button-bg);
                    fill: v(button-bg);
                }
            }
        }
    }
}
